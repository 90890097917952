<template>
  <div class="relative">
    <button
      ref="buttonRef"
      :disabled="items?.length <= 1"
      :id="`${props.elementId}-button`"
      :class="[
        isOpen
          ? 'rounded-t-[4px]  border-white/80'
          : 'rounded-[4px] border-transparent',
        labelClass,
        { 'hover:bg-white/[.15]': items?.length > 1 },
      ]"
      class="trigger outline-none w-full flex gap-x-2 border justify-between items-center bg-white/10 px-4 py-3 text-white opacity-[0.87] text-body-big"
    >
      {{ $t(triggerLabel.label) }}

      <template v-if="items?.length > 1">
        <Icon v-if="isOpen" name="IconChevronUp" size="24"></Icon>
        <Icon v-else name="IconChevronDown" size="24"></Icon>
      </template>
    </button>
    <div
      :id="`${props.elementId}-tooltip`"
      class="z-50 w-full tooltip py-2 flex flex-col border border-white/80 bg-white/10 backdrop-blur-[30px] max-h-56 overflow-y-auto"
    >
      <div class="w-full h-full flex flex-col">
        <button
          v-for="item in items"
          class="option px-4 py-3 flex items-center hover:bg-white/[0.15]"
          @click.stop="onSelection(item)"
        >
          <slot name="optionLabel" :selected-item="item">
            <span
              class="block truncate text-white opacity-[0.87]"
              :class="{ '!opacity-100': props.modelValue?.key == item.key }"
            >
              {{ $t(item.label) }}
            </span>
          </slot>
          <Icon
            v-if="props.modelValue?.key == item.key"
            name="IconChecked"
            size="14"
            class="ms-3"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computePosition } from "@floating-ui/dom";

const props = defineProps({
  elementId: String,
  items: Array,
  placeholder: {
    type: String,
    default: () => "Select an option",
  },
  modelValue: Object,
  labelClass: String,
});

const emit = defineEmits(["update:modelValue", "change"]);

const { t } = useI18n();
const isOpen = ref(false);
const buttonRef = ref();

const triggerLabel = computed(() => {
  if (!props.modelValue) {
    return { id: -1, label: t(props.placeholder) };
  }

  return props.modelValue;
});

function showTooltip() {
  const tooltip = document.querySelector(`#${props.elementId}-tooltip`);
  anchorTooltip();
  tooltip.style.display = "block";
  isOpen.value = true;
}

function hideTooltip(e) {
  // prevent hide if the blur's relatedTarget is button.option
  if (e?.relatedTarget?.classList?.contains("option")) return;
  const tooltip = document.querySelector(`#${props.elementId}-tooltip`);
  tooltip.style.display = "";
  isOpen.value = false;
}

const anchorTooltip = () => {
  const button = document.querySelector(`#${props.elementId}-button`);
  const tooltip = document.querySelector(`#${props.elementId}-tooltip`);
  computePosition(button, tooltip, {
    placement: "bottom-start",
  }).then(({ y }) => {
    Object.assign(tooltip.style, {
      top: `${y}px`,
    });
  });
};

onMounted(async () => {
  await nextTick();
  const button = document.querySelector(`#${props.elementId}-button`);
  anchorTooltip();

  [
    ["pointerdown", toggleTooltip],
    // ["mouseenter", showTooltip],
    // ["mouseleave", hideTooltip],
    ["focus", showTooltip],
    ["blur", hideTooltip],
  ].forEach(([event, listener]) => {
    button.addEventListener(event, listener);
  });
});

const toggleTooltip = () => {
  if (props.items.length <= 1) return;
  (isOpen.value ? hideTooltip : showTooltip)();
};

const onSelection = (item) => {
  emit("update:modelValue", item);
  emit("change", item);
  hideTooltip();
};
</script>

<style lang="scss" scoped>
.tooltip {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  transform-origin: center center;

  &.animated {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
  }
}
</style>
